<head>
    <style>
		ul {
			max-width: 640px;
		}

		.bold {
			font-weight: bold;
		}
    </style>
</head>

<title>MIKO.ART - About</title>
<!--🦄-->
<h1>Haai! I’m Miko <img src="/media/emoji-unicorn.png" height="56" width="56" style="vertical-align: top" alt=""></h1>
<h2>Developer, designer & musician :)</h2>

<img src="/media/miko.jpg" alt="Miko" class="miko" width="300" style="float: right; border-radius: 5px; margin-left: 1em"/>
<p>I’m passionate about creating colorful, creative & educational applications!</p>
<p>Founder & CTO of <a href="https://sketch.io/" target="_blank">Sketch.IO</a> creators of <a href="https://sketchpad.com/" target="_blank">Sketchpad</a> 🎨</p>
<p>Co-Founder of <a href="https://portlandiafoods.com/" target="_blank">Portlandia Foods</a> 🪴 (acquired 2023)</p>
<p>Co-Founder of <a href="https://lyricsfreak.com/" target="_blank">Lyrics Freak</a> 🎵 (acquired 2010)</p>
<!--<p>Co-Founder of <a href="https://beheremeow.app/" target="_blank">Be Here Meow</a> 😸</p>-->

<h4>Exhibits, Selected:</h4>
<ul>
    <li>Art Gallery Of Ontario: Illusions Exhibition</li>
    <li>Dubai Union Metro: Art In Transit</li>
    <li>Google IO 2010: Darkroom</li>
    <li>Google IO 2012: Sketch Mobile</li>
    <li>Google IO 2013: Webcam FX</li>
    <li>Mackinac State Historic Parks</li>
    <li>Tel Aviv Museum Of Modern Art</li>
</ul>

<h4>Clients, Selected:</h4>
<ul>
    <li>AAA</li>
    <li>ABCya Games</li>
    <li>Draw It To Know It: Medical & Biological Sciences</li>
    <li>Google: Made With Code</li>
    <li>Jo Malone London</li>
    <li>Los Alamos National Laboratory</li>
    <li>Portland Timbers: RCTID</li>
    <li>Scotties</li>
    <li>Travel Portland</li>
    <li>Upworthy</li>
</ul>

<h4>Sketch.IO specializes in creating a diverse range of design tools:</h4>

<ul>
    <li><span class="bold">Annotations & Editing for Online News:</span>
        <span>Innovative tools designed to enhance the digital news experience with user-friendly annotations and editing capabilities.</span>
    </li>

    <li><span class="bold">Colorful Educational Musical Apps:</span>
        <span>Interactive and vibrant applications that fuse music with learning, making education both fun and engaging for all ages.</span>
    </li>

    <li><span class="bold">Kiosk Software for Science & Learning Centers:</span>
        <span>Cutting-edge interactive kiosk software, bringing digital innovation to science and educational environments.</span>
    </li>

    <li><span class="bold">Non-Destructive Illustration & Painting Apps:</span>
        <span>Sophisticated apps offering artists and designers the freedom to create with non-destructive editing tools.</span>
    </li>

    <li><span class="bold">Promotional Contests for Sports Teams & Companies:</span>
        <span>Tailored contest platforms to boost engagement for sports teams and businesses, driving promotional success.</span>
    </li>

    <li><span class="bold">Realtime Collaborative Apps:</span>
        <span>Dynamic applications enabling seamless collaboration in real-time, enhancing teamwork and productivity.</span>
    </li>

    <li><span class="bold">User-Based Product Customization for Printing:</span>
        <span>Flexible design tools that allow users to personalize products, perfect for creating unique print-on-demand items.</span>
    </li>
</ul>