<title>MIKO.ART - Labs</title>
<h1>Labs ✨</h1>
<h2>Experiments through the ages.</h2>
<div id="listing">
    <div>
        <a href="/labs/color-galaxy/">
            <img src="../media/dir-listing/folder.png" alt="color-galaxy/"/>
            <strong>Color Galaxy</strong>
        </a>
    </div>
    <div>
        <a href="/labs/js1k/BreathingGalaxies.html">
            <img src="../media/dir-listing/folder.png" alt="js1k/"/>
            <strong>JS1k - Breathing Galaxies</strong>
        </a>
    </div>
    <div>
        <a href="/labs/js1k/Daltonize.html">
            <img src="../media/dir-listing/folder.png" alt="js1k/"/>
            <strong>JS1k - Daltonize</strong>
        </a>
    </div>
    <div>
        <a href="/labs/js1k/MicroSketchpad.html">
            <img src="../media/dir-listing/folder.png" alt="js1k/"/>
            <strong>JS1k - Micro Sketchpad</strong>
        </a>
    </div>
    <div>
        <a href="/labs/js1k/SpectrumDJ.html">
            <img src="../media/dir-listing/folder.png" alt="js1k/"/>
            <strong>JS1k - Spectrum DJ</strong>
        </a>
    </div>
    <div>
        <a href="/labs/midi-js/">
            <img src="../media/dir-listing/folder.png" alt="midi-js/"/>
            <strong>MIDI.js</strong>
        </a>
    </div>
    <div>
        <a href="/labs/sketch-js/">
            <img src="../media/dir-listing/folder.png" alt="sketch-js/"/>
            <strong>Sketch.js</strong>
        </a>
    </div>
    <div>
        <a href="/labs/sketchpad-1.0/">
            <img src="../media/dir-listing/folder.png" alt="sketchpad-1.0/"/>
            <strong>Sketchpad v1.0</strong>
        </a>
    </div>
    <div>
        <a href="/labs/thumbnailer/">
            <img src="../media/dir-listing/folder.png" alt="thumbnailer/"/>
            <strong>Thumbnailer</strong>
        </a>
    </div>
    <div>
        <a href="/labs/zen-bg/">
            <img src="../media/dir-listing/folder.png" alt="zen-bg/"/>
            <strong>Zen BG</strong>
        </a>
    </div>
</div>