export const apps = [
	// {
	// 	id: 'be-here-meow',
	// 	description: 'Where mindfulness meets creativity in a purr-fectly designed app for journaling and personal growth 😸✨.',
	// 	title: 'Be Here Meow',
	// 	url: 'https://beheremeow.app/'
	// },
	{
		id: 'sketchpad',
		description: 'Draw, create & share with this online drawing application. Free for all since 2008. Fun for all ages 🦄!',
		title: 'Sketchpad',
		url: 'https://sketchpad.com/'
	},
	{
		id: 'color-piano',
		description: 'Learn music theory essentials, and visualize amazing composers with this interactive color piano 🎵.',
		title: 'Color Piano',
		url: 'https://colorpiano.com/'
	},
	{
		id: 'color-sphere',
		description: 'Learn color harmony essentials, create color schemes, and export your own color palettes 🎨.',
		title: 'Color Sphere',
		url: 'https://colorsphere.app/'
	},
	// {
	// 	id: 'draw-meow',
	// 	description: 'Generate hypnotic patterns & ambient tones with this colorful interactive audio-visual experience.',
	// 	title: 'Draw Meow',
	// 	url: 'https://drawmeow.app/'
	// },
	// {
	// 	id: 'gradient-designer',
	// 	description: 'Learn color harmony essentials, create color schemes, and export your own color palettes.',
	// 	title: 'Gradient Designer',
	// 	url: 'https://gradientdesigner.app/'
	// },
	// {
	// 	id: 'mindful-journal',
	// 	description: 'Generate hypnotic patterns & ambient tones with this colorful interactive audio-visual experience.',
	// 	title: 'Mindful Journal',
	// 	url: 'https://mindfuljournal.app/'
	// },
	{
		id: 'sand-art',
		description: 'Make your own meditative & beautiful sandscapes through the art of pouring coloured sands 🧘.',
		title: 'Sand Art',
		url: 'https://sandart.app/'
	},
	// {
	// 	id: 'sound-puppet',
	// 	description: 'Make your own meditative & beautiful sandscapes through the art of pouring coloured sands.',
	// 	title: 'Sound Puppet',
	// 	url: 'https://soundpuppet.app/'
	// },
	// {
	// 	id: 'speak-peace',
	// 	description: 'Make your own meditative & beautiful sandscapes through the art of pouring coloured sands.',
	// 	title: 'Speak Peace',
	// 	url: 'https://speakpeace.app/'
	// },
	// {
	// 	id: 'touch-timer',
	// 	description: 'Generate hypnotic patterns & ambient tones with this colorful interactive audio-visual experience.',
	// 	title: 'Touch Timer',
	// 	url: 'https://touchtimer.app/'
	// },
	{
		id: 'melodic-mandalas',
		description: 'Generate hypnotic patterns & ambient tones with this colorful audio-visual interactive experience 🌈.',
		title: 'Zendala',
		url: 'https://zendala.app/'
	}
]

export const art = [
	{
		id: 'meowtains',
		description: 'Lorem ipsum, or lipsum as it is sometimes known',
		title: 'Meowtains!',
		url: 'https://meowtains.com/'
	},
	{
		id: 'growing-ferns',
		description: 'Lorem ipsum, or lipsum as it is sometimes known',
		title: 'Growing Ferns',
		url: 'https://miko.art/labs/generative-growing-ferns/'
	},
	{
		id: 'crystals',
		description: 'Lorem ipsum, or lipsum as it is sometimes known',
		title: 'Crystals',
		url: 'https://miko.art/labs/generative-crystals/'
	},
	{
		id: 'supershapes',
		description: 'Lorem ipsum, or lipsum as it is sometimes known',
		title: 'Supershapes',
		url: 'https://miko.art/labs/generative-supershapes/'
	},
	{
		id: 'cards',
		description: 'Lorem ipsum, or lipsum as it is sometimes known',
		title: 'C.A.R.D.S.',
		url: 'https://miko.art/labs/generative-cards/'
	},
	{
		id: 'glsl',
		description: 'Lorem ipsum, or lipsum as it is sometimes known',
		title: 'GLSL',
		url: 'https://miko.art/labs/webcamfx/'
	}
]